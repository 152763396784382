/*!  
 * DevCard - Bootstrap 4 vCard and Portfolio Theme For Developers
 * Version: 2.1
 * Author: Xiaoying Riley
 * Copyright: 3rd Wave Media Ltd.
 * Website: http://themes.3rdwavemedia.com/
 * Twitter: @3rdwave_themes
*/
@import "mixins";
@import "base";
@import "home";
@import "portfolio";
@import "services";
@import "resume";
@import "blog";
@import "responsive";
@import "darkmode"; //Dark Mode 
@import "demo"; //REMOVE ON YOUR PRODUCTION SITE 
